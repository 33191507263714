<!--添加视频-->
<template>
  <div class="main">
    <div style="font-size: 26px; font-weight: bold; display: flex">
      <div style="color: #c8c8c8;cursor: pointer;" @click="goback">&lt;商品管理/</div>
      <div>添加商品</div>
    </div>
    <div class="title">
      <div class="titleleft">
        <div style="align-items: center">
          <div class="line"></div>
          <div>商品设置</div>
        </div>
        <el-form label-position="right" style="margin-top: 40px">
          <div style="display: flex">
            <!-- <el-form-item label="内容类型：">
              <el-select v-model="value" placeholder="请选择" disabled>
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <!-- <el-form-item label="等级：">
              <el-select v-model="levelId" placeholder="请选择">
                <el-option
                  v-for="item in levelList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="品牌ID：">
              <el-select v-model="brandId" placeholder="请选择">
                <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="商品分类ID：">
            <el-select v-model="categoryId" placeholder="请选择">
              <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品名称：" class="inp">
            <el-input v-model="name" placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="价格：" class="inp asd">
            <el-input v-model="priceMoney" placeholder="请输入价格"></el-input>
            <div>元</div>
          </el-form-item>
          <el-form-item label="积分价格：" class="inp">
            <el-input v-model="pricePoint" placeholder="请输入积分价格"></el-input>
          </el-form-item>
          <el-form-item label="库存：" class="inp">
            <div style="display:flex">
              <el-input v-model="stock" placeholder="请输入库存"></el-input>
              <div style="font-size:12px;white-space:nowrap">(正数为追加库存数，负数为减少库存数)</div>
              <el-button type="primary" @click='updateNum'>保存</el-button>
            </div>

          </el-form-item>
          <el-form-item label="推荐：" class="inp" style="display: flex;align-items: center;">
            <el-switch style="display: block" v-model="isSwitch" 
              >
            </el-switch>

          </el-form-item>
          <el-form-item label="每日用户限购数量：" class="inp">
            <el-input v-model="dateLimitCount" placeholder="请输入每日用户限购数量"></el-input>
          </el-form-item>
          <el-form-item label="每份订单限购数量" class="inp">
            <el-input v-model="orderLimitCount" placeholder="请输入每份订单限购数量"></el-input>
          </el-form-item>
          <el-form-item label="用户限购数量" class="inp">
            <el-input v-model="userLimitCount" placeholder="请输入用户限购数量"></el-input>
          </el-form-item>
          <el-form-item label="上下线时间" class="inp">
            <el-date-picker v-model="time" type="datetimerange" range-separator="至" start-placeholder="开始日期"
              value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="权重：" class="inp">
            <el-input v-model="weight" placeholder="请输入权重(数字越大越在前面)"></el-input>
          </el-form-item>
          <el-form-item label="商品兑换说明：" class="inp">
            <el-input type="textarea" :rows="5" placeholder="请输入商品兑换说明" v-model="textarea">
            </el-input>
          </el-form-item>
          <el-form-item label="缩略图：" class="inp">
            <input type="file" @change="uploadimg" accept="image/*" ref="fileimg" id='fileimg' style="color: #fff" />
          </el-form-item>
          <el-form-item class="inp" v-if="img" label="图片：">
            <div style="position:relative;width:200px">
              <div style="width:100%;height:100%;position:absolute;background: rgba(0,0,0,0.1);"></div>
              <img :src="img" alt="" style="width: 200px" />
              <img @click="deleteImg" src="../../images/delete.png" alt=""
                style="z-index:999;position: absolute;width: 25px;height: 25px;left: 50%;top: 50%;transform: translate(-50%, -50%);width:25px;height:25px">
            </div>


            <!-- <video src=""></video> -->
          </el-form-item>
          <el-form-item label="商品图片：" class="inp">
            <input type="file" @change="uploadimgs" accept="image/*" ref="fileimgs" id='fileimgs' style="color: #fff" />
          </el-form-item>
          <el-form-item class="inp" v-if="imgs.length > 0" label="图片：">
            <div>
              <!-- <div style="width:100%;height:100%;position:absolute;background: rgba(0,0,0,0.1);" ></div> -->
              <div v-for="(item, index) in imgs" :key="index" style="display:flex;position:relative;">
                <img :src="item.picUrl" alt="" style="width: 200px" />
                <img src="../../images/delete.png" @click="deleteimg2(item, index)" alt=""
                  style="z-index:999;position: absolute;width: 25px;height: 25px;left: 50%;top: 50%;transform: translate(-50%, -50%);width:25px;height:25px">
              </div>
            </div>

            <!-- richAnalysis -->
            <!-- <video src=""></video> -->
          </el-form-item>

          <!-- <el-form-item label="描述：">
            <el-input
              type="textarea"
              :rows="5"
              v-model="textarea"
              style="width: 480px"
            >
            </el-input>
             
          </el-form-item> -->
        </el-form>
      </div>

    </div>
    <quill-editor style="height:390px;width:96%;" v-model="msg" ref="richAnalysis" :options="options2"
      @change="quillchange"></quill-editor>

    <div style="display: flex;margin-top: 40px;">
      <el-button type="primary" style="margin: 20px auto; width: 200px" @click="add">添加</el-button>
      <input type="file" @change="uploadimg3" accept='image/*' ref='file3' id="file3" style="display:none" />
      <!-- <el-button
        type="primary"
        style="margin: 20px auto; width: 200px"
        @click="base_videoInfo"
        >添加2</el-button>
    </div> -->
    </div>
  </div>
</template>
<script type="text/javascript" src="https://unpkg.com/mediainfo.js/dist/mediainfo.min.js"></script>
<script>
// 工具栏配置
    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
    
      [{'header': 1}, {'header': 2}],               // custom button values
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
      [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
      [{'direction': 'rtl'}],                         // text direction
    
      [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
      [{'header': [1, 2, 3, 4, 5, 6, false]}],
    
      [{'color': []}, {'background': []}],          // dropdown with defaults from theme
      [{'font': []}],
      [{'align': []}],
      ['link', 'image', 'video'],
      ['clean']                                         // remove formatting button
    ]
// const MediaInfo = require('mediainfo.js')
import MediaInfo from "mediainfo.js";
import { levelList, contentsaveOrUpdate, getCosCredit ,getRichText,teacherList,contentsaveOrAdd,courseInfoList,saveAndToCourse,goodsClassListAll,saveGoods,updateGoods,detailsGoods,updateStock} from "../../apis/index";

var COS = require("cos-js-sdk-v5");
var cos = new COS({
  getAuthorization: function (options, callback) {
    getCosCredit().then((result) => {
      var data = result.data.data;
      var credentials = data && data.credentials;
      if (!data || !credentials) return console.error("credentials invalid");
      sessionStorage.setItem("bucket", data.bucket);
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        XCosSecurityToken: credentials.sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
      });
    });
  },
});
export default {
  data() {
    return {
      isSwitch:true,
      picUrlListAdd:[],
      picUrlListRemove:[],
        classList:[],
        categoryId:'',
        brandList:[],
        brandId:'',
        priceMoney:'',
        pricePoint:'',
        dateLimitCount:'',
        orderLimitCount:'',
        stock:'',
        userLimitCount:'',
        imgs:[],
        time:[],
        textarea:'',
        weight:'',

      levelIds:'',
      idd:'',
      courseId:[],
      name:'',
      teacherId:'',
      teacherName:'',
      list:[],
      list2:[],
      tag:'',
      taskId:'',
      msg:'',
      options2: {
        theme: "snow",
        placeholder: "请输入详细的富文本描述",
        modules:{
          toolbar:{
             container: toolbarOptions,  // 工具栏
              handlers: {
                                'image': function (value) {
                                    if (value) {
                                      document.getElementById('file3').click()
                                        // alert('自定义图片')
                                    } else {
                                        this.quill.format('image', false);
                                    }
                                }
                            }
          }
        }
        // modules: {
        //   toolbar: [
        //     ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
        //     //['blockquote', 'code-block'],                      //引用，代码块
        //     [{ header: 1 }, { header: 2 }], // 几级标题
        //     [{ list: "ordered" }, { list: "bullet" }], // 有序列表，无序列表
        //     [{ script: "sub" }, { script: "super" }], // 下角标，上角标
        //     [{ indent: "-1" }, { indent: "+1" }], // 缩进
        //     //[{ direction: "rtl" }], // 文字输入方向
        //     [{ size: ["small", false, "large", "huge"] }], // 字体大小
        //     [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
        //     [{ color: [] }, { background: [] }], // 颜色选择
        //     [{ font: [] }], // 字体
        //     [{ align: [] }], // 居中
        //     ["clean"],　　　　//清除样式
        //     [("link", "image","video")],//控制能上传的内容
        //   ],
        // },
      },
      enter:false,
      img: "",
      percentage: 0,
      selectFileobj: {},
      homeItem:{},
      id: "",
      name: "",
      levelList: [],
      levelId: "0",
      input: "",
      vidersrc: "",
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      textarea: "",
      value: "2",
      checkList: [],
      radio: "1",
      options: [
        {
          value: "3",
          label: "富文本",
        },
        {
          value: "2",
          label: "视频",
        },
      ],
    };
  },
  watch: {
    isSwitch(newdata,olddata){
      if(newdata){
        this.homeItem.isSwitch=true
      }else{
        this.homeItem.isSwitch=false
      }
    },
  },

  created() {
    
    this.brandList=this.$store.state.brandList
    
    //  this.getdetails()//获取商品详情数据信息
    // this.id=this.$route.query.id
    if (this.$route.query.isadd) {//新增
        this.getclassList()//获取商品分类ID
        this.isSwitch=false
    //      this.leave()
    //   this.teacherlistg()
    //   this.courseInfoListg()
    
    } else {
         let id=JSON.parse(this.$route.query.row).goodsInfo.id
         this.id=id
         if(JSON.parse(this.$route.query.row).homeItem){
          this.homeItem=JSON.parse(this.$route.query.row).homeItem
          if(JSON.parse(this.$route.query.row).homeItem.isValidity==1){
            this.isSwitch=true
          }else{
            this.isSwitch=false
          }
         }else{
          this.isSwitch=false
         }
     Promise.all([this.getclassList(),this.getdetails(id)]).then((res) => {

      });
     
      // console.log(this.levelId)
    }
  },
  beforeDestroy(){
      cos.cancelTask(this.taskId)//离开页面取消上传视频任务 
  },
  methods: {
    deleteimg2(item,index){
      this.$refs["fileimgs"].value=''
      this.picUrlListRemove.push(item)
      let imgs=this.imgs
      imgs.splice(index,1)
      this.imgs=imgs
    },
      updateNum(){
          let params={
             changeStockCount:this.stock, 
             goodsId:this.id
          }
           this.$confirm('请确认是否更新?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           updateStock(params).then(res=>{
            if(res.data.code==200){
              this.$message({
                type: 'success',
                message: '更新成功!'
              });
              this.list()
            }else{
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      },
     async getdetails(id){//获取商品详情数据
         const {data}=await detailsGoods({id:id})
         if(data.code==200){
             let details=data.data.goodsInfo
             console.log(data.data)
            this.brandId=details.brandId,
            this.categoryId=details.categoryId,
            this.dateLimitCount=details.dateLimitCount,
            this.textarea=details.des,
            this.time=[details.startOnlineTime,details.endOnlineTime],
            this.name=details.name,
            this.orderLimitCount=details.orderLimitCount,
            this.priceMoney=details.priceMoney/100,
            this.pricePoint=details.pricePoint,
            this.stock=details.stock,
            this.img=details.thumbNailUrl,
            this.userLimitCount=details.userLimitCount,
            this.weight=details.weight
            this.imgs=data.data.goodsPicVoList
              getRichText(details.richTextUrl).then(res=>{
            this.msg=res.data
            })
            // this.goodsPicList:that.imgs
         }
      },
     async getclassList(){//获取全部分类列表
         const {data}=await goodsClassListAll({parentId:0})
         this.classList=data.data
      },
    changeTeacher(e){
     let obj=this.list.find(item=>{
        return e==item.id
      })
      this.teacherId=obj.id
      this.teacherName=obj.name
    },
     async teacherlistg(){
     const {data}=await teacherList()
     if(data.code==200){
        this.list=data.data
        console.log(data.data)
     }else{
       this.$message.error(data.msg)
     }
     
    },
    async courseInfoListg(){
      const params={
        levelId:this.levelIds,
        pageIndex:1,
        pageSize:999
      }
     const {data}=await courseInfoList(params)
     if(data.code==200){
        this.list2=data.data.records
        console.log(data.data)
     }else{
       this.$message.error(data.msg)
     }
     
    },
    quillchange(delta, oldDelta, source){
      console.log(delta, oldDelta, source)
    },
    deleteImg(){//删除图片
    console.log('删除图片')
    this.$refs["fileimg"].value=''
    this.img=''
    },
    base_videoInfo() {
      const fileinput = document.getElementById("file");
      console.log(fileinput)
      const onChangeFile = (mediainfo) => {
        console.log("监听上了");
        const file = fileinput.files[0];
        if (file) {
          const getSize = () => file.size;
          const readChunk = (chunkSize, offset) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (event) => {
                if (event.target.error) {
                  reject(event.target.error);
                }
                resolve(new Uint8Array(event.target.result));
              };
              reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
            });
          mediainfo
            .analyzeData(getSize, readChunk)
            .then((result) => {
              console.log(result);
              console.log(result.media.track[1].Format);
              // console.log(result.media.track[1].Format)
              //result.media.track[1].Format == "AVC"说明是h264
              if (result.media.track[1].Format != "AVC") {
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      };
      MediaInfo({ format: "object" }, (mediainfo) => {
        //format结果值的格式（选择：object，JSON，XML，HTML或text）
        fileinput.addEventListener("change", () => onChangeFile(mediainfo));
      },(err)=>{
        console.log(err)
      });
    },
    uploadvideo(e) {
      //上传视频
      if (this.$refs["file"].files[0]) {
        let filename = this.$refs["file"].files[0].name;
        let selectFileobj = this.$refs["file"].files[0];
        console.log(selectFileobj);
        this.upload(selectFileobj)
        // this.base_videoInfo()
      }
    },

    upload(selectFileobj) {
      let that = this;
      cos.options.getAuthorization("", function (obj) {
        let name = selectFileobj.name.replace(/MP4/g, "mp4");
        if(that.levelId==''){
          that.$message.error('请先选择等级')
          document.getElementById('file').value=''
          return 
        }
        let filename ='content/resource/LV'+that.levelId+'/'+'video/'+
          name.slice(0, name.length - 4) +
          new Date().getTime() +
          name.slice(-4);
          console.log(filename)
        cos.sliceUploadFile(
          {
            Bucket: sessionStorage.getItem("bucket") /* 必须 */,
            Region: "ap-nanjing" /* 存储桶所在地域，必须字段 */,
            Key: filename /* 必须 */,
            Body: selectFileobj /* 必须 */,
            onTaskReady: function (taskId) {
              /* 非必须 */
              console.log(taskId);
              that.taskId=taskId
            },
            onHashProgress: function (progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData));
            },
            onProgress: function (progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData));
              that.percentage = parseInt(progressData.percent * 100);
            },
          },
          function (err, data) {
            console.log(err || data);
            if (data) {
               let Location=data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g,'https://sihuankangyuan.cn')
              // that.vidersrc = "https://" + data.Location;
              that.vidersrc=Location

               setTimeout(()=>{
                //   var videosrc = document.getElementById('videosrc');
                // that.duration=parseInt(videosrc.duration)
                // console.log(parseInt(videosrc.duration))
                 that.$message({
                  message: `上传成功，视频正在转码请稍后再播放`,
                  type: 'warning',
                });
               },1000)
            }
          }
        );
      });
    },
     richText(selectFileobj){
      let that=this
    //    if(that.levelId==''){
    //       that.$message.error('请先选择等级')
    //       return 
    //     }
        let randomNum=Math.random().toString().slice(2)
        let filename ='content/resource/richText/'+randomNum+new Date().getTime()
        this.filename=filename
          console.log(filename)
     var cos = new COS({
            getAuthorization: function (options, callback) {
              getCosCredit().then(result=>{
                 var data = result.data.data;
                        var credentials = data && data.credentials;
                        if (!data || !credentials) return console.error('credentials invalid');
                       sessionStorage.setItem('bucket', data.bucket)
                        callback({
                            TmpSecretId: credentials.tmpSecretId,
                            TmpSecretKey: credentials.tmpSecretKey,
                            XCosSecurityToken: credentials.sessionToken,
                            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                            StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                            ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
                        });
              })
            }
        });
        cos.options.getAuthorization ('',function(obj){
          cos.putObject({
          Bucket: sessionStorage.getItem('bucket'), /* 必须 */
          Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
          Key: filename,              /* 必须 */
          StorageClass: 'STANDARD',
          Body: selectFileobj, // 上传文件对象
          onProgress: function(progressData) {
              // console.log(JSON.stringify(progressData));
              console.log(progressData)
          }
        }, function(err, data) {
          console.log(err || data);
          if(data){
            if(!that.img){
              that.$message.error('请上传商品缩略图')
              return
            }
                 // type: this.value,
            // type: 4,//富文本和视频合并
            // levelId: that.levelId,
            // name: that.name,
            // des: that.textarea,
            // videoUri: that.vidersrc,
            // resourceUri:that.filename,
            // logoUri: that.img,
            // id: that.id,
            // courseIdList:that.courseId,
            // teacherName:that.teacherName,
            // teacherUserId:that.teacherId,
            // tag:that.tag
            let homeItem={}
            let homeItemData=that.homeItem
            if(that.isSwitch){
              homeItem=homeItemData
              homeItemData.isValidity=1
              
            }else{
              homeItem=homeItemData
              homeItem.isValidity=0
            }
           const paramsupdate = {
            brandId:that.brandId,
            categoryId:that.categoryId,
            dateLimitCount:that.dateLimitCount,
            des:that.textarea,
            startOnlineTime:that.time[0],
            endOnlineTime:that.time[1],
            name:that.name,
            orderLimitCount:that.orderLimitCount,
            priceMoney:parseFloat((that.priceMoney*100).toFixed(2)),
            // priceMoney:that.priceMoney,
            pricePoint:that.pricePoint,
            richTextUrl:that.filename,
            stock:that.stock,
            thumbNailUrl:that.img,
            userLimitCount:that.userLimitCount,
            weight:that.weight,
            goodsPicList:that.imgs,
            picUrlListRemove:that.picUrlListRemove,
            picUrlListAdd:that.picUrlListAdd,
            id:that.id,
            homeItem
          };
          let homeItem2=that.isSwitch?{}:{isValidity:0}
          const paramsadd = {
            brandId:that.brandId,
            categoryId:that.categoryId,
            dateLimitCount:that.dateLimitCount,
            des:that.textarea,
            startOnlineTime:that.time[0],
            endOnlineTime:that.time[1],
            name:that.name,
            orderLimitCount:that.orderLimitCount,
            priceMoney:parseFloat((that.priceMoney*100).toFixed(2)),
            // priceMoney:that.priceMoney,
            pricePoint:that.pricePoint,
            richTextUrl:that.filename,
            stock:that.stock,
            thumbNailUrl:that.img,
            userLimitCount:that.userLimitCount,
            weight:that.weight,
            goodsPicList:that.imgs,
            homeItem:homeItem2
          };
          console.log(paramsadd)
          if(!that.$route.query.isadd){//修改
            updateGoods(paramsupdate).then(res=>{
                  if(res.data.code==200){
                that.$message.success('操作成功')
                that.$router.go(-1)
              }else{
                that.$message.error(res.data.msg)
              }
                })
          }else{
            // contentsaveOrAdd(params).then(res=>{
            //       if(res.data.code==200){
            //     that.$message.success('操作成功')
            //     that.$router.go(-1)
            //   }else{
            //     that.$message.error(res.data.msg)
            //   }
            //     })
            // console.log(params)
            saveGoods(paramsadd).then(res=>{
                  if(res.data.code==200){
                that.$message.success('操作成功')
                that.$router.go(-1)
              }else{
                that.$message.error(res.data.msg)
              }
                })
          }
         
        // console.log(params)
            //   let url='https://'+data.Location
            //  let quill = that.$refs.richAnalysis.quill
            //  let length = quill.getSelection().index;
            // // 插入图片，url为服务器返回的图片链接地址
            // quill.insertEmbed(length, 'image', url)
            // // 调整光标到最后
            // quill.setSelection(length + 1)
          }
        });
        })
         
    },
    uploadimg(e) {
      //上传图片
      if (this.$refs["fileimg"].files[0]) {
        let filename = this.$refs["fileimg"].files[0].name;
        let selectFileobj = this.$refs["fileimg"].files[0];
        console.log(this.$refs["fileimg"].files[0])
        // console.log(selectFileobj)
        this.upload2(selectFileobj);
      }
    },
     uploadimgs(e) {
      //商品图片
      if (this.$refs["fileimgs"].files[0]) {
        let filename = this.$refs["fileimgs"].files[0].name;
        let selectFileobj = this.$refs["fileimgs"].files[0];
        console.log(this.$refs["fileimgs"].files[0])
        // console.log(selectFileobj)
        this.uploads(selectFileobj);
      }
    },
    uploadimg3(e){//上传图片
      console.log('我是点击富文本上传图片')
      if(this.$refs['file3'].files[0]){
        let filename = this.$refs['file3'].files[0].name
        let selectFileobj = this.$refs['file3'].files[0]
        // console.log(selectFileobj)
        this.upload3(selectFileobj)

      }
    },
     upload3(selectFileobj){
      let that=this
      let name = selectFileobj.name
    //    if(that.levelId==''){
    //       that.$message.error('请先选择等级')
    //       document.getElementById('file3').value=''
    //       return 
    //     }
        let filename ='content/resource/image/'+
          name.slice(0, name.length - 4) +
          new Date().getTime() +
          name.slice(-4);
          console.log(filename)
     var cos = new COS({
            getAuthorization: function (options, callback) {
              getCosCredit().then(result=>{
                 var data = result.data.data;
                        var credentials = data && data.credentials;
                        if (!data || !credentials) return console.error('credentials invalid');
                       sessionStorage.setItem('bucket', data.bucket)
                        callback({
                            TmpSecretId: credentials.tmpSecretId,
                            TmpSecretKey: credentials.tmpSecretKey,
                            XCosSecurityToken: credentials.sessionToken,
                            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                            StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                            ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
                        });
              })
            }
        });
        cos.options.getAuthorization ('',function(obj){
          cos.putObject({
          Bucket: sessionStorage.getItem('bucket'), /* 必须 */
          Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
          Key: filename,              /* 必须 */
          StorageClass: 'STANDARD',
          Body: selectFileobj, // 上传文件对象
          onProgress: function(progressData) {
              // console.log(JSON.stringify(progressData));
              console.log(progressData)
          }
        }, function(err, data) {
          console.log(err || data);
          if(data){
           
              // let url='https://'+data.Location
               let url=data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g,'https://sihuankangyuan.cn')
             let quill = that.$refs.richAnalysis.quill
             let length = quill.getSelection().index;
            // 插入图片，url为服务器返回的图片链接地址
            quill.insertEmbed(length, 'image', url)
            // 调整光标到最后
            quill.setSelection(length + 1)
          }
        });
        })
         
    },
     uploads(selectFileobj) {
      let that = this;
      let name = selectFileobj.name
    //    if(that.levelId==''){
    //       that.$message.error('请先选择等级')
    //       document.getElementById('fileimg').value=''
    //       return 
    //     }
        let filename ='content/resource/image/'+
          name.slice(0, name.length - 4) +
          new Date().getTime() +
          name.slice(-4);
          console.log(filename)
      cos.options.getAuthorization("", function (obj) {
        cos.putObject(
          {
            Bucket: sessionStorage.getItem("bucket") /* 必须 */,
            Region: "ap-nanjing" /* 存储桶所在地域，必须字段 */,
            Key: filename /* 必须 */,
            StorageClass: "STANDARD",
            Body: selectFileobj, // 上传文件对象
            onProgress: function (progressData) {
              // console.log(JSON.stringify(progressData));
              console.log(progressData);
            },
          },
          function (err, data) {
            console.log(err || data);
            if (data) {
              let Location=data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g,'https://sihuankangyuan.cn')
              // let url = "https://" + data.Location;
              
              //  console.log(Location)
              that.imgs.push({
                  picUrl:Location
              });
               that.picUrlListAdd.push({
                  picUrl:Location
              });
            //   console.log(that.imgs)
            }
          }
        );
      });
    },
    upload2(selectFileobj) {
      let that = this;
      let name = selectFileobj.name
    //    if(that.levelId==''){
    //       that.$message.error('请先选择等级')
    //       document.getElementById('fileimg').value=''
    //       return 
    //     }
        let filename ='content/resource/image/'+
          name.slice(0, name.length - 4) +
          new Date().getTime() +
          name.slice(-4);
          console.log(filename)
      cos.options.getAuthorization("", function (obj) {
        cos.putObject(
          {
            Bucket: sessionStorage.getItem("bucket") /* 必须 */,
            Region: "ap-nanjing" /* 存储桶所在地域，必须字段 */,
            Key: filename /* 必须 */,
            StorageClass: "STANDARD",
            Body: selectFileobj, // 上传文件对象
            onProgress: function (progressData) {
              // console.log(JSON.stringify(progressData));
              console.log(progressData);
            },
          },
          function (err, data) {
            console.log(err || data);
            if (data) {
              let Location=data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g,'https://sihuankangyuan.cn')
              // let url = "https://" + data.Location;
              
              //  console.log(Location)
              that.img = Location;
            }
          }
        );
      });
    },
    async leave() {
      //获取等级列表
      const params = {
        roleId: 3,
      };
      const { data } = await levelList(params);
      if (data.code == 200) {
        // const obj={
        //    id:'',
        //    name:'全部'
        //  }
        //  this.levelList=[obj,...data.data]
        this.levelList = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
    async add() {
      this.richText(this.msg)
      // const params = {
      //   // type: this.value,
      //   type: 4,//富文本和视频合并
      //   levelId: this.levelId,
      //   name: this.name,
      //   des: this.textarea,
      //   videoUri: this.vidersrc,
      //   resourceUri:this.msg,
      //   logoUri: this.img,
      //   id: this.id,
      // };
      // const { data } = await contentsaveOrUpdate(params);
      // if (data.code == 200) {
      //   this.$message.success("操作成功");
      //   this.$router.go(-1);
      // } else {
      //   this.$message.error(data.msg);
      // }
    },
    goback() {
      this.$router.go(-1);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-form-item__content {
  display: flex
}

.main {
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  div {
    display: flex;
  }

  .line {
    width: 9px;
    height: 22px;
    border-radius: 6px;
    background: #409eff;
    margin-right: 11px;
  }
}

.title>div {
  width: 48%;
  margin: 29px 0;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  //   align-items: center;

}

.titleleft {
  display: flex;
  flex-direction: column;
}

/deep/.el-form-item__label {
  width: 100px;
}

/deep/.el-radio__label,
/deep/.el-checkbox__label {
  display: flex;
  align-items: center;
}

.inp /deep/.el-form-item__content {
  width: 88%;
}
</style>
